<template>
  <BSidebar id="account-sidebar" right sidebar-class="sidebar-lg" shadow backdrop :width="sidebarWidth" header-class="p-0" bg-variant="white" :visible="isAccountSidebarActive"
    @change="val=>$emit('update:is-account-sidebar-active', val)" @hidden="$emit('hidden')">
    <template #header="{ hide }"><!-- Header -->
      <div class="d-flex flex-column w-100">
        <div class="w-100 d-flex justify-content-between align-items-center content-sidebar-header px-2 py-2 bg-white">
          <h4 v-if="selectedAccount" class="mb-0 text-capitalize text-primary">
            {{selectedAccount.name}}
            <small class="ml-50">{{selectedAccount.code}}</small>
            <q-badge :label="selectedAccount.class" class="ml-1" :color="getColor('account',selectedAccount.class)"></q-badge>
          </h4>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide"/>
        </div>
        <q-tabs v-model="tab" class="border-bottom bg-light-secondary inner-shadow" active-color="primary" indicator-color="primary" align="justify" narrow-indicator>
          <q-tab name="transactions" icon="mail" :label="$t('finance.transaction.transactions')"  />
          <q-tab name="edit" icon="edit" :label="$t('edit')" />
        </q-tabs>
      </div>
    </template>

    <template #default><!-- BODY -->
      <q-tab-panels v-model="tab" value="transactions" animated transition-prev="jump-left" transition-next="jump-right" class="h-100">
        <q-tab-panel name="transactions" class="p-0">
          <q-scroll-area class="h-100" delay='600'
            :thumb-style="{ right: '4px', borderRadius: '5px', backgroundColor: '#7367f0', width: '5px', opacity: 0.6 }"
            :bar-style="{right: '2px', borderRadius: '9px', backgroundColor: '#7367f0', width: '9px',background: 'dark', opacity: 0.2}">
            <AccountSidebarTransactions />
          </q-scroll-area>
        </q-tab-panel>
        <q-tab-panel name="edit" class="px-0">
          <q-scroll-area class="h-100" delay='600'
            :thumb-style="{ right: '4px', borderRadius: '5px', backgroundColor: '#7367f0', width: '5px', opacity: 0.6 }"
            :bar-style="{right: '2px', borderRadius: '9px', backgroundColor: '#7367f0', width: '9px',background: 'dark', opacity: 0.2}">
          <AccountSidebarEdit @closeSidebar="$emit('closeSidebar')" class="mx-2"/>
          </q-scroll-area>
        </q-tab-panel>
      </q-tab-panels>
    </template>
  </BSidebar>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import useAccount from '../useAccount'
import { BSidebar, BButton } from 'bootstrap-vue'
import { getColor } from '@core/utils/filter'
import AccountSidebarTransactions from './sidebar/AccountSidebarTransactions'
import AccountSidebarEdit from './sidebar/AccountSidebarEdit'

export default {
  name: 'account-sidebar',
  components: {
    BSidebar, BButton,
    AccountSidebarTransactions,
    AccountSidebarEdit
  },
  props:{
    isAccountSidebarActive: {
      type: Boolean,
      default: false
    }
  },
  setup(props, {emit}){
    const sidebarWidth = ref('800px')
    const { tab, selectedAccount, selected, fetchselectedAccount, fetchSelectedTransactions } = useAccount(props, emit)
    watch(selected, (val)=>{
      // 更新 store 中 selectedAccount 数据 -> 不在useAccount中, 为避免重复
      if (val) {
        fetchselectedAccount(val)
        fetchSelectedTransactions(val)
      }
    })

    // 根据 tab 调节宽度
    watch(tab, val=>{
      if (val==='transactions') sidebarWidth.value = "800px"
      if (val==='edit') sidebarWidth.value = ''
    })
    
    return {
      getColor,
      selectedAccount,
      tab,
      sidebarWidth
    }
  }
}
</script>

<style lang="scss">
html {
  overflow: hidden;
}
#account-sidebar{
  transition: all 500ms;
  
  //Hide scrollbar
  ::-webkit-scrollbar {
    display: none;
  }
  .b-sidebar-body {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }
}
</style>