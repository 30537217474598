<template>
  <div class="d-flex gap-2 align-items-start flex-wrap">
    <div class="p-2 bg-white rounded-lg shadow flex-even">
      <AccountSidebar :isAccountSidebarActive.sync="isAccountSidebarActive" @hidden="()=>selected=null" @showSidebar="isAccountSidebarActive=true" @closeSidebar="isAccountSidebarActive=false" />
      <div class="text-right">
        <BButton v-if="!isAddEntry" push rounded class="mb-2 shadow-sm" variant="primary" glossy @click="isAddEntry=!isAddEntry"><fa-icon icon="plus" class="mr-50" />Journal</BButton>
      </div>
      <div class="balance-sheet d-flex flex-wrap gap-4">
        <div class="left flex-even">
          <AccountGroup name="asset" showTitle :filter="filters" :isAddEntry="isAddEntry" @accountValue="updateAddedAccounts" color="primary" :editData="accountGroupEditData"/>
        </div>
        <q-separator vertical class="d-none d-sm-inline"/>
        <div class="right d-flex flex-column flex-even gap-3 justify-content-between">
          <AccountGroup name="liability" showTitle :filter="filters" :isAddEntry="isAddEntry" @accountValue="updateAddedAccounts" color="warning" :editData="accountGroupEditData" />
          <AccountGroup name="equity" showTitle :filter="filters" :isAddEntry="isAddEntry" @accountValue="updateAddedAccounts" color="secondary" :editData="accountGroupEditData" />
        </div>
      </div>

      <q-separator class="my-2" />
          
      <div class="profit-lost d-flex flex-wrap gap-4">
        <div class="left flex-even">
          <AccountGroup name="expense" showTitle :filter="filters" :isAddEntry="isAddEntry" @accountValue="updateAddedAccounts" color="danger" :editData="accountGroupEditData" />
        </div>
        <q-separator vertical class="d-none d-sm-inline"/>
        <div class="right flex-even b1order-left-secondary">
          <AccountGroup name="revenue" showTitle :filter="filters" :isAddEntry="isAddEntry" @accountValue="updateAddedAccounts" color="success" :editData="accountGroupEditData" />
        </div>
      </div>

      <q-separator v-if="isAddEntry" class="my-2" />

      <!-- DR CR -->
      <div v-if="isAddEntry" class="d-flex gap-4">
        <div class="flex-even flex-size-2 p-1 h5 m-0 rounded-lg d-flex justify-content-between" :class="balance.dr!==balance.cr?'bg-light-secondary':'bg-light-success'">
          <span class="mr-1">DR</span><span>{{balance.dr | price}}</span>
        </div>
        <q-separator vertical class="d-none d-sm-inline" />
        <!-- <div v-else class="flex-even d-flex align-items-center p-1 h5 m-0 bg-light-danger rounded-lg justify-content-between"><fa-icon :icon="['far','triangle']"/> {{balance.d | price}}</div> -->
        <div class="flex-even flex-size-2 p-1 h5 m-0 rounded-lg d-flex justify-content-between" :class="balance.dr!==balance.cr?'bg-light-secondary':'bg-light-success'">
          <span class="mr-1">CR</span><span>{{balance.cr | price}}</span>
        </div>
      </div>

      <div v-if="isAddEntry && balance.d" class="d-flex gap-4 mt-2" :class="balance.dr>balance.cr?'flex-row-reverse':''">
        <div class="flex-even d-flex align-items-center p-1 h5 m-0 bg-light-danger rounded-lg justify-content-between"><fa-icon :icon="['far','triangle']"/> {{balance.d | price}}</div>
        <q-separator vertical class="bg-white d-sm-inline" />
        <div class="flex-even p-1"></div>
      </div>
    </div>

    <!-- Add Entry -->
    <div v-if="isAddEntry" class="sidebar col-lg-3 d-flex flex-column gap-3">
      <q-expansion-item expand-separator icon="filter_alt" :label="$t('finance.account_filters')" class="border-primary rounded-lg shadow" header-class="bg-light-primary">
        <div class="p-1 rounded-lg">
          <h5><fa-icon :icon="['fad','random']" class="mr-1" fixed-width/>{{$t('finance.action._')}}</h5>
          <q-btn-toggle no-caps class="flex-nowrap mb-2" glossy v-model="filters.actionFilter" push toggle-color="primary" 
            :options="JSON.parse(JSON.stringify($global.actionFilterOptions)).map(item=>{item.label = $t(item.label); return item}).concat([{value:'', slot:'cancel'}])">
            <template #cancel><fa-icon icon="times" /></template>
          </q-btn-toggle>
          <h5><fa-icon :icon="['fad','user']" class="mr-1" fixed-width/>{{$t('finance.role._')}}</h5>
          <q-btn-toggle no-caps class="flex-nowrap mb-2" glossy v-model="filters.roleFilter" push toggle-color="primary" 
            :options="JSON.parse(JSON.stringify($global.roleFilterOptions)).map(item=>{item.label = $t(item.label); return item}).concat([{value:'', slot:'cancel'}])">
            <template #cancel><fa-icon icon="times" /></template>
          </q-btn-toggle>
          <h5><fa-icon :icon="['fad','dollar-sign']" class="mr-1" fixed-width/>{{$t('finance.type._')}}</h5>
          <q-btn-toggle no-caps class="flex-nowrap mb-2" glossy v-model="filters.typeFilter" push toggle-color="primary" 
            :options="JSON.parse(JSON.stringify($global.typeFilterOptions)).map(item=>{item.label = $t(item.label); return item}).concat([{value:'', slot:'cancel'}])">
            <template #cancel><fa-icon icon="times" /></template>
          </q-btn-toggle>
          <h5>Include more entries</h5>
          <q-input ref="treeFilterRef" dense filled v-model="filters.additional" label="More with ," color="primary">
            <template v-slot:append>
              <q-icon v-if="filters.additional !== ''" name="clear" class="cursor-pointer" @click="resetTreeFilter" />
            </template>
          </q-input>
        </div>
      </q-expansion-item>
      
      <div class="shadow rounded-lg p-1 bg-white d-flex flex-column gap-2">
        <!-- details -->
        <q-input type="text" v-model="formObj.details" class="pb-0" filled :label="$t('form.details')" :loading="loading" autocomplete="off" clearable lazy-rules filled hide-bottom-space dense
          :rules="[ val=>val !== null && val !== undefined && val !== '' || $t('rule.required'), val=>val.length<=50 || 'Too long']">
          <template #prepend><fa-icon :icon="['fad','file-signature']" fixed-width class="text-primary"/></template>
        </q-input>
        <FlatPickr v-model="formObj.date" class="form-control" :config="{maxDate:'today'}" placeholder="Date"/>
        <!-- tags -->
        <q-select v-model="formObj.tags" outlined :label="$t('tags')" new-value-mode="add-unique" :loading="loading" multiple hide-dropdown-icon use-input dense >
          <template #selected-item="scope">
            <q-chip dense removable @remove="scope.removeAtIndex(scope.index)" :tabindex="scope.tabindex" color="light-primary" text-color="primary">{{scope.opt}}</q-chip>
          </template>
          <template #prepend><fa-icon :icon="['fad','tags']" fixed-width class="text-primary"/></template>
        </q-select>
        <FileUpload multiple automatic :action="`${$global.server_url}/entry/${entryId?entryId+'/file':'file'}`" fileName="files" :files="entryId?entryData.files:[]" model="entry"
          @on-success="successUpload" @on-error="failUpload" @on-delete="deleteUpload" class="mt-0" />
        <div class="mt-2 d-flex justify-content-between">
          <BButton v-if="entryId" variant="primary" :disabled="!formObj.details || balance.d!==0" @click.prevent.stop="submitForm" class="shadow-sm">{{$t('update') | capitalize}}</BButton>
          <BButton v-else variant="primary" :disabled="!formObj.details || balance.d!==0" @click.prevent.stop="submitForm" class="shadow-sm">{{$t('action.add') | capitalize}}</BButton>
          <BButton variant="danger" @click.prevent.stop="entryId?$router.back(-1):isAddEntry=false" class="shadow-sm">{{$t('cancel') | capitalize}}</BButton>
        </div>
      </div>
      
    </div>
  </div>
</template>

<script>
import { getCurrentInstance } from '@vue/composition-api'
import { ref, set, watch } from '@vue/composition-api'
import useAccount from './useAccount'
import store from '@/store'
import { useRouter } from '@/@core/utils/utils'

import { toNumber, price, capitalize } from '@core/utils/filter'
import AccountSidebar from './components/AccountSidebar'
import AccountGroup from './components/AccountGroup'

import { BBadge, BButton } from 'bootstrap-vue'
import 'vuesax/dist/vuesax.css'
import FileUpload from '@core/components/file-upload/FileUpload'
import FlatPickr from 'vue-flatpickr-component'
import { Toast, Dialog } from '@/@core/utils/other'

import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  name: 'account-list',
  components:{
    BBadge, BButton,
    AccountSidebar,
    AccountGroup,
    FileUpload,
    FlatPickr,

    AppCollapse,
    AppCollapseItem,
  },
  filters:{
    price, capitalize
  },
  setup(){
    const vm = getCurrentInstance().proxy
    const {
      loading, 
      isAccountSidebarActive,
      selected,
      fetchedData,
      accountsRef,
      fetchAccounts,
    } = useAccount()

    // this page only
    const isAddEntry = ref(false)
    watch(isAddEntry, val=>{
      store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', val)
      if (val===false) {
        resetFilters()
        resetForm()
      }
    })

    const filters = ref({roleFilter:'', actionFilter:'', typeFilter:'', additional:''})
    const resetFilters  = ()=>{
      filters.value = {roleFilter:'', actionFilter:'', typeFilter:'', additional:''}
    }
    const resetTreeFilter = function(){
      filters.value.additional = ''
      //treeFilterRef.value.focus()
    }

    // Add
    const {route, router} = useRouter()
    if (route.value.query.add) isAddEntry.value = true
    let entryId = route.value.params.entryId // entryId in URL

    const balance = ref({dr:0, cr:0})
    const addedTransactionsObj = ref({})
    const emptyFormObj = ()=>({files:[], tags:[], date:new Date().toLocaleDateString()})
    const formObj = ref(emptyFormObj())
    const updateAddedAccounts = ({account, drcr, amount}) => {
      if(!addedTransactionsObj.value) addedTransactionsObj.value = {}
      set(addedTransactionsObj.value, account, {account, drcr, amount})
    }

    // Edit
    // 当传入 entry ID
    const accountGroupEditData = ref({})
    const entryData = ref({})
    watch(()=>route.value.params.entryId, val=>{
      entryId = route.value.params.entryId
      if (val){
        isAddEntry.value = true
        // fetch entry
        store.dispatch('account/fetchEntry', entryId).then(({entry}) => {
          entryData.value = entry
          const transactions = entry.transactions
          if(!transactions || transactions.length===0) router.back(-1)

          // 更新formObj
          formObj.value = {
            _id: entry._id,
            details: entry.details,
            date: entry.date,
            tags: entry.tags || [],
            files: entry.files || [],
          }

          // 更新data传入tree
          const newArray = transactions.map(transaction=>({
            account: transaction.account._id, // account ID
            drcr: transaction.account.drcr,
            amount: transaction.amount
          }))
          
          for(let item of newArray){
            set(accountGroupEditData.value, item.account, item.amount) // 传递给 accountGroup 组件
            set(addedTransactionsObj.value, item.account, item) // 传递给 formObj
          }
        }).catch(e => {router.back(-1)})
      }
    }, {immediate: true})

    const resetForm = () => formObj.value = emptyFormObj()

    // 提交表单    
    const submitForm = ()=>{
      let addedAccountArray = []
      for(let key in addedTransactionsObj.value){
        if (addedTransactionsObj.value[key].amount) addedAccountArray.push(addedTransactionsObj.value[key])
      }
      if (!formObj.value || !formObj.value.details || balance.value.d!==0 || addedAccountArray.length===0) return Dialog(false).fire({title: 'Form submission error'})
      loading.value = true
      store.dispatch(`account/${entryId?'updateEntry':'addEntry'}`, {...formObj.value, transactions:addedAccountArray})
        .then(() => {
          Toast.fire({icon:'success', title: vm.$t(entryId?'updated':'added')})
          loading.value = false
          isAddEntry.value = false
          if(entryId){
            router.back(-1)
          } else {
            fetchAccounts()
          }
        }).catch(error => {
          console.log(error);
          loading.value = false
          Toast.fire({icon:'error', title: error.message})
        })
    }

    // 计算balance
    watch(addedTransactionsObj, val => {
      balance.value = {dr:0, cr:0, d:0}
      if (!val) return
      for(let node in val){
        if (val[node].drcr==='dr') balance.value.dr = toNumber(balance.value.dr + val[node].amount||0)
        if (val[node].drcr==='cr') balance.value.cr = toNumber(balance.value.cr + val[node].amount||0)
      }
      balance.value.d = toNumber(Math.abs(balance.value.dr - balance.value.cr))
    },{deep: true, immediate: true})

    // 文件上传
    const successUpload = function(e){
      if (!formObj.value._id){ //只有 Add new 才上传
        const uploadedObj = JSON.parse(e.target.response).files;
        if (uploadedObj && uploadedObj.length>0) formObj.value.files = formObj.value.files.concat(uploadedObj) // 上传的图片信息
      }
      Toast.fire({icon:'success', title:'Success'})
    }

    const failUpload = function(e){
      console.log(e);
      Toast.fire({icon:'error', title: 'upload failed'})
    }

    const deleteUpload = function(e){
      store.dispatch('account/removeFile', {
        model:'entry',
        id: formObj.value._id || null,
        fileName: e.newFileName
      }).then(() => {
        if(!formObj.value._id){ //如果是Add guard
          // 移除照片信息
          for (const [i, file] of formObj.value.files.entries()) {
            if (file.name === e.newFileName) delete formObj.value.files.splice(i,1)
          }
        }
        Toast.fire({icon:'success', title: 'File deleted'})
      }).catch(e=>{
        Toast.fire({icon:'error', title: JSON.parse(e).message})
      })
    }
    
    fetchAccounts()

    return {
      // This page only
      loading,
      isAddEntry,
      filters,

      // Tree
      resetTreeFilter,

      selected,
      isAccountSidebarActive,

      accountsRef,
      fetchedData,

      fetchAccounts,

      // Add / Update
      formObj,
      addedTransactionsObj,
      updateAddedAccounts,
      balance,
      submitForm,
      accountGroupEditData, // accountGroup的输入
      entryId, // URL传入
      entryData,

      // Upload
      successUpload,
      failUpload,
      deleteUpload
    }
  }

}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
.sidebar {
  transition: opacity 0.5s;
}
.slide-enter-active, .slide-leave-active {
  transition: opacity .5s;
}
.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}

.q-select {
  div.q-field__native{
    column-gap: 0.5rem;
  }
}
.text-size-150 {
  font-size: 1.5rem
}
</style>