<template>
  <BTable ref="refTransactions" class="slim position-relative text-center" :items="selectedTransactions" responsive :fields="tableColumns"
    primary-key="_id" :sort-by.sync="sortBy" show-empty :sort-desc.sync="isSortDirDesc" @row-clicked="row=>$set(row, '_showDetails', !row._showDetails)">
    <template #empty>
      <fa-icon class="my-3 text-secondary" :icon="['fad','cauldron']" size="4x" />
      <h5>{{$t("table.noResult")}}</h5>
    </template>
      <template #row-details="row">
      <AccountSidebarEntry v-if="row.item._showDetails && row.item.entry && row.item.entry._id" :entryId="row.item.entry._id" :highlightId="row.item._id" showEditBtn/>
    </template>

    <template #cell(entry.date)="{ item }">
      <h5 class="text-primary m-0">{{item.entry.date | date}}</h5>
    </template>

    <template #cell(account.name)="{ item }">
      <div class="col"><BBadge class="mx-75 w-100 text-capitalize" :class="'bg-'+getColor('account', item.account.class)">{{item.account.name}}</BBadge></div>
    </template>

    <template #cell(amount)="{ item }">
      <h5 class="text-primary m-0 text-right" :class="item.amount<0?'text-danger':'text-success'">
        {{(item.amount >= 0)?'':'-'}} {{ item.currency}} {{ Math.abs(item.amount) | price}}
      </h5>
    </template>

    <template #cell(entry.tags)="{ item }">
      <div class="d-flex flex-wrap gap-1">
        <BBadge v-for="(tag, index) in item.entry.tags" variant="light-primary" :key="tag[index]">{{tag}}</BBadge>
      </div>
    </template>
  </BTable>
</template>

<script>
import { ref, computed } from '@vue/composition-api'
import useAccount from '../../useAccount'
import { BTable, BBadge } from 'bootstrap-vue'
import { price, date, getColor } from '@core/utils/filter'
import AccountSidebarEntry from './AccountSidebarEntry'

export default {
  name: "account-sidebar-transactions",
  components:{
    BTable, BBadge,
    AccountSidebarEntry
  },
  filters: {
    price, date
  },
  setup(){
    const { selectedTransactions } = useAccount()

    const tableColumns = [
      { key: 'entry.date', field:'entry.date', label:'Date', sortable: true },
      { key: 'entry.details', label: 'Details', sortable: true},
      { key: 'account.name', label:'Account', sortable: true},
      { key: 'amount', label:'Amount', sortable: true },
      { key: 'entry.tags', field:'entry.tags', label:'Tags', sortable: true }
    ]

    // Table
    const refTransactions = ref(null)

    const perPage = ref(25)
    const totalProducts = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('_id')
    const isSortDirDesc = ref(true)

    const filterInputs = ref({})
    const filterSelects = ref({})

    const filterTable = ref({status: {$ne: 'salecompleted'}})
    const filterTableOptions =ref([
      {title:'yet completed', value: {status: {$ne: 'salecompleted'}}},
      {title:'all', value:null}
    ])

    // Table selection
    const selectedData = ref([])
    const onRowSelected = (val)=>{selectedData.value = val}

    // 延迟
    let awaitingStartTime = 0;
    const awaitAction = (func, duration=750) => {
      let selfStartTime = + new Date()
      awaitingStartTime = selfStartTime;
      setTimeout(()=>{
        if (awaitingStartTime === selfStartTime) func()
      }, duration)
    }

    const dataMeta = computed(() => {
      const localItemsCount = refTransactions.value ? refTransactions.value.localItems.length : 0
      return {
        from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
        to: perPage.value * (currentPage.value - 1) + localItemsCount,
        of: totalProducts.value,
      }
    })

    return {
      refTransactions,

      tableColumns,
      selectedTransactions,
      
      perPage,
      currentPage,
      totalProducts,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,

      onRowSelected,
      getColor
    }
  }

}
</script>

<style>

</style>