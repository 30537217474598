import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import accountStoreModule from './accountStoreModule'
if (!store.hasModule('account')) store.registerModule('account', accountStoreModule)
// Notification
import { swal, Toast } from '@core/utils/other'

export default function useAccount(props, emit) {
  const loading = ref(false)
  const fetchedData = ref(null) // accounts

  // Accounts
  const fetchAccounts = () => {
    store.dispatch('account/fetchAccounts').then(response => {
      fetchedData.value = response.accounts
    }).catch((error) => {
      console.log(error);
    })
  }

  // Tree
  const ticked = ref([])
  const tickStrategy = ref('leaf')
  const treeFilterRef = ref(null)

  // Sidebar
  const isSidebarActive = ref(false)
  const tab = ref('transactions')
  const showSidebar = () =>{
    if(emit) emit('showSidebar')
  }
  const closeSidebar = ()=>{
    if(emit) emit('closeSidebar')
  }
  const selected = ref(null) // selected account ID for sidebar
  watch(selected, (val)=>{
    if (props && props.isAddEntry === true) return
    if (val) { // 更新 ID
      store.commit('account/UPDATE_SELECTED', val)
      showSidebar()
    } else {
      store.commit('account/RESET_SELECTED')
    }
  })
  watch(()=>store.state.account.selected, (val)=> {
    selected.value = val
  })

  // Sidebar data
  const selectedAccount = ref(store.state.account.selectedAccount)
  const selectedTransactions = ref(store.state.account.selectedTransactions)
  // 更新 Store 中的 selectedAccount
  watch(()=>store.state.account.selectedAccount, val=> selectedAccount.value = val, {deep: true})
  // 更新 Store 中的 selectedTransactions
  watch(()=>store.state.account.selectedTransactions, val=> selectedTransactions.value = val, {deep: true})

  const fetchselectedAccount = (id) => {
    store.dispatch('account/fetchselectedAccount', (id||store.state.account.selected))
      .then(() => {})
      .catch((error) => {Toast.fire({
        icon: 'error',
        title: `${error.message}`
      })
    })
  }
  const fetchSelectedTransactions = (id) => {
    store.dispatch('account/fetchSelectedTransactions', (id||store.state.account.selected))
      .then(() => {})
      .catch((error) => {
        Toast.fire({
        icon: 'error',
        title: `${error.message}`
      })
    })
  }
  
  const fetchTransactions = (id) => {
    store.dispatch('account/fetchTransactions', id).then(response => {
      selectedAccount.value.transactions = response.transactions
    }).catch((error) => {
      Toast.fire({
        icon: 'error',
        title: `${error.message}`
      })
    })
  }

  return {
    loading,
    swal,

    // Tree
    ticked,
    tickStrategy,
    treeFilterRef,

    // Sidebar
    tab,
    isSidebarActive,
    showSidebar, closeSidebar,

    selected, selectedAccount, selectedTransactions,

    fetchedData,
    fetchselectedAccount, // id-> account
    fetchSelectedTransactions, // id-> [transactions]
    fetchAccounts, // null -> {[accounts]}
    fetchTransactions, // id=> [transactions]

    Toast,
  }
}
