<template>
  <div id="account-group">
    <q-tree no-results-label=" " v-if="fetchedData" default-expand-all :nodes="[fetchedData]" labelKey="name" node-key="_id" :selected.sync="selected" selected-color="primary" 
      :filter="JSON.stringify(filter)" :filter-method="treeFilterMethod" >
      <template #default-header="{node}">
        <div class="w-100 d-flex gap-2 justify-content-between m-0" :class="node.level===2?`h3 text-${color}`:''">
          <div class="d-flex align-items-center gap-2">
            <div class="font-weight-bold" :class="node.isGroup&&node.class!==fetchedData.name?'text-weight-bolder':'text-'+color+''">
              <component :is="node.isGroup&&node.class!==fetchedData.name?'u':'span'" class="text-capitalize">{{node.names.find(n=>n.locale===$i18n.locale).name}}</component>
            </div>
            <small>{{node.code}}</small>
            <!-- 存货数量 -->
            <!-- <q-chip v-if="node.isInventory && !node.isGroup && node.quantity" dense color="primary" text-color="white" class="my-0">{{node.quantity}}</q-chip> -->
          </div>
          <div v-if="!isAddEntry" :class="'space-'+ (node.level-3)">
            <span :class="node.sum<0?'text-danger':(node.sum>0?'text-success':'')">
              <!-- <template v-if="node.name===fetchedData.name">
                <fa-icon v-if="node.sum>0" icon="caret-up" class="mr-50" />
                <fa-icon v-if="node.sum<0" icon="caret-down" class="mr-50" />
              </template> -->
              {{(node.sum >= 0)?'':'-'}} 
              <component :is="node.isGroup&&node.class!==fetchedData.name?'u':'span'">{{Math.abs(node.sum) | price}}</component>
            </span>
          </div>
          <!-- 显示add entry -->
          <div v-if="isAddEntry && node.isGroup===false">
            <q-input filled clearable hide-bottom-space @click.stop :color="color" type="number" dense class="m-0" v-model="addedAccounts[node._id]" 
              :rules="[ val=>val === null || val === undefined || val===NaN || val==='' || parseFloat(val) === toNumber(val) || '2 decimals only']"
              @change="event=>addAccountUpdate(node, event)" 
              @clear="addAccountUpdate(node)"></q-input>
          </div>
        </div>
      </template>
    </q-tree>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import store from '@/store'
import useAccount from '../useAccount'
import { price, toNumber } from '@core/utils/filter'
import { BBadge } from 'bootstrap-vue'

export default {
  name: 'account-group',
  components:{
    BBadge,
  },
  filters: {
    price
  },
  props:{
    name: {
      type: String,
      required: true
    },
    showTitle:{
      type: Boolean,
      default: false
    },
    color: String,
    isAddEntry: {
      type: Boolean,
      default: false
    },
    filter:{
      type: Object,
      default:{}
    },
    editData: {
      type: Object
    }
  },
  setup(props, {emit}){
    const {
      //tree
      treeFilterRef,
      //treeFilterMethod,
      resetTreeFilter,
      selected,

      fetchedData
    } = useAccount(props, emit)

    // Add new entry时的accounts值 => 只是在这个页面存值
    const addedAccounts = ref({})

    watch(()=>props.editData, val=>{
      addedAccounts.value = JSON.parse(JSON.stringify(val))
    }, {deep: true, immediate: true})

    const addAccountUpdate = (node, event)=>{
      emit('accountValue', {
        account: node._id, 
        drcr: node.drcr, 
        amount: event?parseFloat(event.target.value):null,
      })
    }

    // 如果store变化, 更新数据
    watch(()=>store.state.account.accounts[props.name], (obj)=>{
      fetchedData.value = obj
      calculateSumAmountOfChildren(fetchedData.value)
    }, {deep: true})

    // 计算节点 sum
    const calculateSumAmountOfChildren = function(node){
      if (!node) return 
      if (!node.children || node.children.length === 0) {
        // 无children
        node.sum = node.amount||0
      } else {
        // 有children
        node.sum =  node.children.reduce((sum, child)=>{
          child.sum = calculateSumAmountOfChildren(child)
          return sum + child.sum
        }, 0)
      }
      return node.sum
    }

    // Filter 更新树形结构
    const treeFilterMethod = (node, filters_str) => {
      const showAccount = (show)=>{
        if (show) return true
        if (addedAccounts.value[node._id]){
          addedAccounts.value[node._id] = null // Reset node input
          addAccountUpdate(node, null)
         }
        return false
      }
      const filters = JSON.parse(filters_str)
      // 显示master account (asset, liability, etc.)
      if (props.name && node.class=== props.name) return showAccount(true)

      // 额外显示
      if (filters.additional){ 
        let match = false
        filters.additional.split(',').forEach(filter=>{
          if (filter.trim()&&node.name && node.name.toLowerCase().indexOf(filter.trim().toLowerCase()) > -1 && node.isGroup === false) match = true
        })
        if (match) return showAccount(true)
      }
      // 没有 filters => 显示全部
      if (!filters || (!filters.actionFilter && !filters.roleFilter && !filters.typeFilter)) return showAccount(true)
      // 组类别不显示
      if (node.isGroup) return showAccount(false)
      // account无filter不显示
      if (!node.filters || !node.filters.length) return showAccount(false)
      // Role
      if (filters.roleFilter) {
        let show = false
        node.filters.forEach(filter=>{
          if (filter.toLowerCase() === filters.roleFilter.toLowerCase()) show = true
        })
        if (!show) return showAccount(false)
      }
      // Action
      if (filters.actionFilter) {
        let show = false
        node.filters.forEach(filter=>{
          if (filter.toLowerCase() === filters.actionFilter.toLowerCase()) show = true
        })
        if (!show) return showAccount(false)
      }
      // Type
      if (filters.typeFilter) {
        let show = false
        node.filters.forEach(filter=>{
          if (filter.toLowerCase() === filters.typeFilter.toLowerCase()) show = true
        })
        if (!show) return showAccount(false)
      }
      return showAccount(true)
    }

   
    return {
      fetchedData,
      toNumber,

      //tree
      selected,
      treeFilterRef,
      treeFilterMethod,
      resetTreeFilter,

      //Add
      addedAccounts,
      addAccountUpdate,
    }
  }

}
</script>

<style lang="scss" scoped>
@media screen and (min-width:768px) {
  @for $i from 1 through 10 {
    .space-#{$i} {
      margin-right: #{$i}rem 
    }
  }
}
</style>

<style lang="scss">
#account-group {
  .q-field--dense .q-field__control, .q-field--dense .q-field__marginal {
    height: 36px;
  }
}
</style>