var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BTable',{ref:"refTransactions",staticClass:"slim position-relative text-center",attrs:{"items":_vm.selectedTransactions,"responsive":"","fields":_vm.tableColumns,"primary-key":"_id","sort-by":_vm.sortBy,"show-empty":"","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event},"row-clicked":function (row){ return _vm.$set(row, '_showDetails', !row._showDetails); }},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('fa-icon',{staticClass:"my-3 text-secondary",attrs:{"icon":['fad','cauldron'],"size":"4x"}}),_c('h5',[_vm._v(_vm._s(_vm.$t("table.noResult")))])]},proxy:true},{key:"row-details",fn:function(row){return [(row.item._showDetails && row.item.entry && row.item.entry._id)?_c('AccountSidebarEntry',{attrs:{"entryId":row.item.entry._id,"highlightId":row.item._id,"showEditBtn":""}}):_vm._e()]}},{key:"cell(entry.date)",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-primary m-0"},[_vm._v(_vm._s(_vm._f("date")(item.entry.date)))])]}},{key:"cell(account.name)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"col"},[_c('BBadge',{staticClass:"mx-75 w-100 text-capitalize",class:'bg-'+_vm.getColor('account', item.account.class)},[_vm._v(_vm._s(item.account.name))])],1)]}},{key:"cell(amount)",fn:function(ref){
var item = ref.item;
return [_c('h5',{staticClass:"text-primary m-0 text-right",class:item.amount<0?'text-danger':'text-success'},[_vm._v(" "+_vm._s((item.amount >= 0)?'':'-')+" "+_vm._s(item.currency)+" "+_vm._s(_vm._f("price")(Math.abs(item.amount)))+" ")])]}},{key:"cell(entry.tags)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex flex-wrap gap-1"},_vm._l((item.entry.tags),function(tag,index){return _c('BBadge',{key:tag[index],attrs:{"variant":"light-primary"}},[_vm._v(_vm._s(tag))])}),1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }