import { render, staticRenderFns } from "./AccountSidebar.vue?vue&type=template&id=2698e455&"
import script from "./AccountSidebar.vue?vue&type=script&lang=js&"
export * from "./AccountSidebar.vue?vue&type=script&lang=js&"
import style0 from "./AccountSidebar.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QTab from 'quasar/src/components/tabs/QTab.js';
import QTabPanels from 'quasar/src/components/tab-panels/QTabPanels.js';
import QTabPanel from 'quasar/src/components/tab-panels/QTabPanel.js';
import QScrollArea from 'quasar/src/components/scroll-area/QScrollArea.js';
import qInstall from "../../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(component, 'components', {QBadge,QTabs,QTab,QTabPanels,QTabPanel,QScrollArea});
