<template>
  <div v-if="selectedAccount" id="account-sidebar-edit">
    <q-form v-if="selectedAccount.code%1000!==0" @submit.prevent="updateAccount()">
      <h5 class="text-primary"><fa-icon :icon="['far','sync']" class="mr-1"/>Update Account</h5>
      <!-- Account details -->
      <div class="d-flex gap-2 mt-2">
        <q-input ref="nameRef" type="text" v-model="name" label="Name" outlined :disabled="selectedAccount.code%1000===0" :loading="loading"
          :rules="[ val=>val !== null && val !== undefined && val !== '' || 'Cannot be blank', val=>val.length<=25 || 'Name is too long']" />
        <q-input ref="codeRef" type="number" v-model="code" label="Code" outlined :disabled="selectedAccount.code%1000===0" :loading="loading" step="any"
          :rules="[ val=>val !== null && val !== undefined && val !== NaN || 'Code is required', codeValidate]" />
      </div>
      <!-- Account Filter -->
      <div v-if="!selectedAccount.isGroup" class="mb-2">
        <!-- Action -->
        <h5><fa-icon :icon="['fad','random']" class="mr-1" fixed-width/>{{$t('finance.action.filters')}}</h5>
        <q-checkbox v-for="item in $global.actionFilterOptions" :key="item.label" v-model="filters" :val="item.value" :label="$t(item.label)" />
        <!-- Role -->
        <h5 class="mt-1"><fa-icon :icon="['fad','user']" class="mr-1" fixed-width/>{{$t('finance.role.filters')}}</h5>
        <q-checkbox v-for="item in $global.roleFilterOptions" :key="item.label" v-model="filters" :val="item.value" :label="$t(item.label)" />
        <!-- Type -->
        <h5 class="mt-1"><fa-icon :icon="['fad','user']" class="mr-1" fixed-width/>{{$t('finance.type.filters')}}</h5>
        <q-checkbox v-for="item in $global.typeFilterOptions" :key="item.label" v-model="filters" :val="item.value" :label="$t(item.label)" />
      </div>
      <!-- Buttons -->
      <div class="d-flex justify-content-between">
        <q-btn :disabled="!$refs.nameRef || $refs.nameRef.hasError ||!$refs.codeRef || $refs.codeRef.hasError" color="primary" push size="md" type="submit">Update</q-btn>
        <q-btn color="danger" push size="md" type="text" @click.prevent="deleteGroup">Delete</q-btn>
      </div>
      <q-separator class="my-2"/>
    </q-form>

    <h5 class="text-primary"><fa-icon :icon="['far','plus']" class="mr-1"/>Add ledger account</h5>
    <q-tree v-if="selectedAccount.isGroup" class="mt-1" default-expand-all :nodes="treeObj" labelKey="name" node-key="_id" selected-color="primary">
      <template #default-header="{node}">        
        <div class="d-flex align-items-center gap-2">
          <template v-if="node.label">
            <q-btn color="primary" rounded icon="create_new_folder" label="Add" @click.stop="toggleNew" />
          </template>
          <template v-else>
            <div class="text-capitalize font-weight-bold">{{node.name}}</div><small>{{node.code}}</small>
          </template>
        </div>
      </template>
      <template #default-body="{node}">
        <div v-if="node.active===true">
          <div class="p-1 rounded-lg border-secondary mt-50">
            <q-form @submit="addAccount()" class="d-flex flex-column gap-3">
              <div class="d-flex gap-2 align-items-center">
                <q-toggle v-model="newAccount.isGroup"/>
                <span v-if="newAccount.isGroup"><fa-icon fixed-width icon="sitemap" class="mr-1 text-primary" />Child group</span>
                <span v-else><fa-icon fixed-width icon="project-diagram" class="mr-1 text-primary" />Ledger account</span>
              </div>
              
              <q-input label="Name" type="text" class="p-0" outlined v-model="newAccount.name" :rules="[ val=>val !== null && val !== undefined && val !== '' || 'Cannot be blank', val=>val.length<25 || 'Name is too long']"/>
              <q-input label="Code" type="number" class="p-0" step="any" outlined v-model="newAccount.code" :rules="[val=>val !== NaN && val !== undefined && val !== null || 'Cannot be blank', codeValidate]"/>
              <q-input v-if="!newAccount.isGroup" label="Opening balance" type="number" outlined v-model="newAccount.openingBalance" />
              <q-btn v-if="node.active" push color="success" type="submit" :loading="loading"><fa-icon icon="check" class="mr-1" />Add</q-btn>
            </q-form>
          </div>
        </div>
      </template>
    </q-tree>
    <span v-else>Can only add account under group</span>
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import useAccount from '../../useAccount'
import store from '@/store'
export default {
  name: 'account-sidebar-edit',
  setup(_, {emit}){
    const { 
      loading,
      Toast,
      fetchAccounts, fetchselectedAccount,
      selectedAccount,
      closeSidebar,
      swal
    } = useAccount(_, emit)

    const treeObj = ref([selectedAccount.value])
    const name = ref(selectedAccount.value.name)
    const code = ref(selectedAccount.value.code)
    const filters = ref(selectedAccount.value.filters||[])

    // 添加acocunt child
    const newAccount = ref(null)

    const updateTreeObj = function(){
      newAccount.value = {
        label: 'Add sub account',
        name: '',
        code: 0,
        filters: [],
        isGroup: true, 
        active: false,
        openingBalance: 0
      }

      if (!selectedAccount.value) return treeObj.value = []

      treeObj.value = [JSON.parse(JSON.stringify(selectedAccount.value))]
      if (!treeObj.value[0].children) treeObj.value[0].children = []
      // 在 group 下, 显示添加选项
      if (selectedAccount.value.isGroup) return treeObj.value[0].children.unshift(newAccount.value)
    }
    const toggleNew = () => newAccount.value.active = !newAccount.value.active
  
    updateTreeObj()

    watch(selectedAccount, val=>{
      if(val && val.name) name.value = val.name;
      if(val && val.code) code.value = val.code;
      if(val) filters.value = val.filters;
      updateTreeObj()
    }, {deep: true})

    const codeValidate = val=>{
      if (!selectedAccount.value.code) return 'No code range adviced';
      const index = parseInt(selectedAccount.value.code.toString()[0])
      const min = index*1000
      const max = (index+1)*1000
      return (val > min && val < max) || `Code need to be within ${min+1} ~ ${max-1}`
    }

    // 更新Account数据
    const updateAccount = () => {
      loading.value = true
      store.dispatch('account/updateAccount', {
        id: store.state.account.selectedAccount._id,
        name: name.value,
        code: code.value,
        filters: filters.value
      }).then(()=>{
        Toast.fire({ icon:'success', title:'Update successfully!' })
        fetchAccounts() //更新store/account数据 => Account list page
        fetchselectedAccount() // 更新selectedAccount
        closeSidebar()
        loading.value=false
      }).catch(error=>{
        Toast.fire({icon:'error', title: error.message})
        loading.value=false
      })
    }

    // Add group
    const addAccount = () => {
      loading.value = true
      let obj = {
        parent: selectedAccount.value._id,
        name: newAccount.value.name,
        code: newAccount.value.code,
        isGroup: newAccount.value.isGroup,
      }
      if (!newAccount.value.isGroup) obj.openingBalance = newAccount.value.openingBalance||0
      store.dispatch(`account/addAccount`, obj).then(()=>{
        Toast.fire({ icon:'success', title:`Successfully added` })
        fetchAccounts() //更新store/account数据 => Account list page
        fetchselectedAccount() // 更新selectedAccount
        loading.value=false
      }).catch(error=>{
        Toast.fire({icon:'error', title: error.message})
        loading.value=false
      })
    }

    const deleteGroup = ()=>{
      swal.fire({
      title: 'Confirm to remove account?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, remove!',
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ml-1',
      },
      buttonsStyling: false,
      heightAuto: false
    }).then(v => {
      if(!v.value) return
      loading.value = true
      store.dispatch('account/deleteAccount', {
        id: selectedAccount.value._id
      }).then(()=>{
        Toast.fire({ icon:'success', title:`${selectedAccount.name} removed` })
        fetchAccounts() //更新store/account数据 => Account list page
        loading.value=false
        closeSidebar()
      }).catch(error=>{
        Toast.fire({icon:'error', title: error.message})
        loading.value=false
      })
    })}

    return {
      loading,
      name, code, filters,
      updateAccount,
      selectedAccount,

      newAccount,
      treeObj,
      toggleNew,
      codeValidate,

      addAccount, deleteGroup
    }
    
  }
}
</script>
